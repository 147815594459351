/* eslint-disable lit/no-legacy-template-syntax */
import {GlobalTranslations} from "@adc/utilities/global-translations";
import { TranslationDataController } from '@adc/utilities/translation-data-controller';
import "@aileron/button";
import "@aileron/link";
import "@aileron/toast";
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import styles from './styles.scss';
import { CookieBannerTranslations } from './translations';

@customElement('adc-cookie-banner')
export class CookieBanner extends LitElement {

  @property() name = 'CookieBanner';

  @property() policyUrl = 'https://www.aa.com/i18n/customer-service/support/privacy-policy.jsp';

  @property({ type: String, attribute: "locale", reflect: true }) locale = TranslationDataController.retrieveLocale();

  static styles = styles;

  render() {
    TranslationDataController.getLocaleTranslations(this.locale, CookieBannerTranslations);
    TranslationDataController.getLocaleTranslations(this.locale, GlobalTranslations);
    const showBanner = this.showCookieBanner();
    return showBanner ?  html `
      <adc-toast id="cookie-banner" class="adc-cookie-banner" heading="${TranslationDataController.translations['componentHeader']}" on-load-focus>
	      <adc-icon slot="leading-icon" size="24" icon="signal:information"></adc-icon>
        <div> ${TranslationDataController.translations['componentBody']}
          <div class="link-padding">
            <adc-link href="${this.policyUrl}" target="_blank" hiddenLabelText="${TranslationDataController.translations['newWinText']}" icon="new-window">${TranslationDataController.translations['linkText']}</adc-link>
          </div>
        </div>
        <div slot="toast-buttons">
            <adc-button type="button" id="toast-dismiss-button" size="fullwidth" @click=${this.handleClose}>
                ${TranslationDataController.translations['buttonText']}
            </adc-button>
        </div>
      </adc-toast>` : undefined;
  }

  handleClose() {
    this.setCookie('cookie_banner','closed');
    this.parentNode?.removeChild(this);
  }

  getCookie(cname:string) {
    const name = `${cname  }=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.startsWith(' ')) {
        c = c.substring(1);
      }
      if (c.startsWith(name)) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname:string, cvalue:string) {
    const d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000*365));
    const expires = `expires=${ d.toUTCString()}`;
    const domainParts = window.location.hostname.split('.');
    const domain = domainParts.slice(-2).join('.');
    document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${domain}`;
  }

  showCookieBanner(): boolean {
    const akaCrCodeValue = this.getCookie('aka_cr_code');
    return (this.getCookie('cookie_banner') !== 'closed' && (akaCrCodeValue.includes('US') || akaCrCodeValue === ''));
  }

}

declare global {
  interface HTMLElementTagNameMap {
    "adc-cookie-banner": CookieBanner;
  }


}
