/**
 * Roll up data used to return content
 * if input locale data does not exist in translation files
 */
 // eslint-disable-next-line @typescript-eslint/no-extraneous-class
 export class CookieBannerTranslations {
  /**
   * @ignore
   */
  public static DATA: any = {
    'en_US': {
      'componentHeader': 'Privacy and cookies',
      'componentBody': 'We use cookies on our website. Your interactions and personal data may be collected on our websites by us and our partners in accordance with our privacy policy.',
      'buttonText': 'Dismiss',
      'linkText': 'Privacy policy.'
    },
    'es': {
      'componentHeader': 'Privacidad y cookies',
      'componentBody': 'Usamos cookies en nuestro sitio web. Su actividad y datos personales podrían ser recopilados en nuestros sitios web por nosotros y nuestros socios, de acuerdo a nuestra política de privacidad.',
      'buttonText': 'Ignorar',
      'linkText': 'Política de privacidad'
    },
    'fr': {
      'componentHeader': 'Confidentialité et cookies',
      'componentBody': 'Nous utilisons des cookies sur notre site Web. Vos interactions et vos données personnelles peuvent être collectées sur nos sites Web par nous et nos partenaires, conformément à notre politique de confidentialité.',
      'buttonText': 'Fermer',
      'linkText': 'Politique de confidentialité'
    },
    'pt': {
      'componentHeader': 'Privacidade e Cookies',
      'componentBody': 'Utilizamos cookies no nosso website. As suas interações e os seus dados pessoais podem ser recolhidos nos nossos websites por nós e pelos nossos parceiros, de acordo com a nossa política de privacidade.',
      'buttonText': 'Ignorar',
      'linkText': 'Política de privacidade'
    },
    'es_ES': {
      'componentHeader': 'Privacidad y cookies',
      'componentBody': 'Utilizamos cookies en nuestro sitio web. Nosotros y nuestros socios podemos recopilar sus interacciones y datos personales en nuestros sitios web de acuerdo con nuestra política de privacidad.',
      'buttonText': 'Descartar',
      'linkText': 'Política de privacidad'
    },
    'de_DE': {
      'componentHeader': 'Datenschutz und Cookies',
      'componentBody': 'Wir verwenden Cookies auf unserer Website. Ihre Interaktionen und personenbezogene Daten können auf unseren Websites von uns und unseren Partnern in Übereinstimmung mit unserer Datenschutzrichtlinie erfasst werden.',
      'buttonText': 'Ablehnen',
      'linkText': 'Datenschutzrichtlinien'
    },
    'zh_CN': {
      'componentHeader': '隐私和Cookie',
      'componentBody': '我们会在网站上使用Cookie。我们及我们的合作伙伴可能会根据我们的隐私政策在网站上收集您的互动情况和个人数据。',
      'buttonText': '拒绝',
      'linkText': '隐私政策'
    },
    'zh_HK': {
      'componentHeader': '私隱及 Cookies',
      'componentBody': '我們在網站上使用 cookies。我們與合作夥伴可能會根據我們的私隱政策在網站上收集您的互動和個人資料。',
      'buttonText': '關閉',
      'linkText': '私隱政策'
    },
    'pt_PT': {
      'componentHeader': 'Privacidade e Cookies',
      'componentBody': 'Utilizamos cookies no nosso website. As suas interações e os seus dados pessoais podem ser recolhidos nos nossos websites por nós e pelos nossos parceiros, de acordo com a nossa política de privacidade.',
      'buttonText': 'Ignorar',
      'linkText': 'Política de privacidade'
    },
    'pt_BR': {
      'componentHeader': 'Privacidade e cookies',
      'componentBody': 'Nós usamos cookies em nosso site. Suas interações e dados pessoais podem ser coletados em nossos sites por nós e por nossos parceiros de acordo com a nossa política de privacidade.',
      'buttonText': 'Ignorar',
      'linkText': 'Política de privacidade'
    },
    'nl_NL': {
      'componentHeader': 'Privacy en cookies',
      'componentBody': 'We gebruiken cookies op onze website. Uw interacties en persoonsgegevens kunnen door ons en onze partners op onze websites worden verzameld in overeenstemming met ons privacybeleid.',
      'buttonText': 'Afwijzen',
      'linkText': 'Privacybeleid'
    },
    'fr_FR': {
      'componentHeader': 'Confidentialité et cookies',
      'componentBody': 'Nous utilisons des cookies sur notre site Web. Vos interactions et vos données personnelles peuvent être collectées sur nos sites Web par nous et nos partenaires, conformément à notre politique de confidentialité.',
      'buttonText': 'Fermer',
      'linkText': 'Politique de confidentialité'
    },
    'fr_CA': {
      'componentHeader': 'Confidentialité et témoins',
      'componentBody': 'Nous utilisons des témoins sur notre site Web. Vos interactions et vos données personnelles peuvent être recueillies sur nos sites Web par nous et nos partenaires conformément à notre politique de confidentialité.',
      'buttonText': 'Refuser',
      'linkText': 'Politique de confidentialité'
    },
    'fi_FI': {
      'componentHeader': 'Tietosuoja ja evästeet',
      'componentBody': 'Käytämme verkkosivustollamme evästeitä. Me ja kumppanimme saatamme kerätä vuorovaikutustasi ja henkilötietojasi verkkosivustoillamme tietosuojakäytäntömme mukaisesti.',
      'buttonText': 'Ohita',
      'linkText': 'Tietoturvakäytäntö'
    },
    'ko_KR': {
      'componentHeader': '개인정보 보호 및 쿠키',
      'componentBody': '아메리칸항공은 당사 웹사이트에서 쿠키를 사용합니다. 귀하의 상호작용 및 개인 데이터는 당사의 개인 정보 처리 방침에 따라 아메리칸항공과 당사의 파트너가 아메리칸항공 웹사이트에서 수집할 수 있습니다.',
      'buttonText': '거부',
      'linkText': '개인 정보 처리 방침'
    },
    'ja_JP': {
      'componentHeader': 'プライバシーとCookie',
      'componentBody': '弊社ウェブサイトでは、Cookieを使用しています。弊社のプライバシーポリシーに従い、お客様がやり取りされる情報やお客様の個人データは、弊社および弊社の提携パートナーにより本ウェブサイト上で収集される場合があります。',
      'buttonText': '同意しない',
      'linkText': 'プライバシーポリシー'
    },
    'it_IT': {
      'componentHeader': 'Privacy e cookie',
      'componentBody': 'Utilizziamo i cookie sul nostro sito web. Le interazioni e i dati personali dell’utente possono essere raccolti sui nostri siti Web da noi e dai nostri partner in conformità con la nostra politica sulla privacy.',
      'buttonText': 'Ignora',
      'linkText': 'Informativa sulla privacy'
    }
  };
}
