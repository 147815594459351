import axios from 'axios';
import { Rollup } from '../../assets/languageRollup';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class TranslationDataController {
  static isCookieSetFromURL = false;

  static translations: {
    [key: string]: string;
  } = {};

  static getLocaleTranslations(locale, componentTranslations) {
    const updateLocale = this.transformLocaleFormat(locale);
    const rollupLocales = ['en_US', ...(Rollup.DATA.rollup[updateLocale] || []).reverse(), updateLocale];
    for(const code of rollupLocales) {
      TranslationDataController.translations = {...TranslationDataController.translations, ...componentTranslations.DATA[code] };
    }
  }

  private static transformLocaleFormat(s: string) {
    return s.replace('-', '_');
  }

  //retrive locale from cookie
  private static  _checkCookie(): string {
    let cookie = "en_US";
    const cookiesList = this.getCookies("sessionLocale");
    if (cookiesList.length > 0) {
      cookie = this.transformLocaleFormat(cookiesList[0]);
    }
    return cookie;
  }

  static updateCookie(urlParamValue: string) {
    const cookiesList = this.getCookies("sessionLocale");

    if(cookiesList.length > 1) {
      document.cookie = `sessionLocale=;max-age=0`;
    }
    document.cookie = `sessionLocale=${urlParamValue}; path=/`;

  }

  static retrieveLocale(): string {
    let locale = "en_US";
    const urlParamValue = this.getURLParam();

    //if url param is not null
    if (urlParamValue) {
      const isCorrectFormat = this.checkLocaleFormat(urlParamValue);

      if(isCorrectFormat){
        locale = this.transformLocaleFormat(urlParamValue);
        if(!this.isCookieSetFromURL) {
          this.updateCookie(locale);
          this.isCookieSetFromURL = true;
        }
      }
    } else {
      const cookieValue = this._checkCookie();
      if (cookieValue !== null && cookieValue !== undefined) {
        locale = this.transformLocaleFormat(cookieValue);
      }
    }
    return locale;
  }

  static getURLParam(){
    const currentPageURL = new URLSearchParams(window.location.search);
    const urlParamValue = Object.fromEntries(currentPageURL.entries())?.locale;
    return (urlParamValue !== null && urlParamValue !== undefined) ? urlParamValue : '';
  }

  static async getTranslationData(componentName:string, protocol:string): Promise<any>{
    const locale = this.retrieveLocale();
    const baseUrl = document.location.hostname === 'localhost' ? 'iqa2.qa.aa.com' : document.location.hostname;
    if (locale in Rollup.DATA.rollup) {
      const locales = [locale, ...Rollup.DATA.rollup[locale], 'en_US'];

      for (const loc of locales) {
        try {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const response = await axios.get(`${protocol}//${baseUrl}/pubcontent/${loc}/fragments/navigation/${componentName}/${componentName}-${loc}.json`);
          return response;
        }
        catch (error) {
          continue;
        }
      }
    }
    else {
      try {
        const response = await axios.get(`${protocol}//${baseUrl}/pubcontent/${locale}/fragments/navigation/${componentName}/${componentName}-${locale}.json`);
        return response;
      }
      catch (error) {
        return error;
      }
    }
  }

  static getLocaleRollup(localeCode) {
    return Rollup.DATA.rollup[localeCode];
  }

  static checkLocaleFormat(localeCode) {
    const pattern = new RegExp('^[a-z]{2}_[A-Z]{2}$');
    return pattern.test(localeCode);
  }

  static getCookies(cname:string) {
    const name = `${cname  }=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    const cookieList: string[] = [];
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.startsWith(' ')) {
        c = c.substring(1);
      }
      if (c.startsWith(name)) {
        cookieList.push(c.substring(name.length, c.length));
      }
    }
    return cookieList;
  }

}
