/**
 * Roll up data used to return content
 * if input locale data does not exist in translation files
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Rollup {
    /**
     * @ignore
     */
    public static DATA: any = {
      'rollup': {
        'en_AG': ['en_CB'],
        'en_AI': ['en_CB'],
        'en_AW': ['en_CB'],
        'en_BB': ['en_CB'],
        'en_BM': ['en_CB'],
        'en_BQ': ['en_CB'],
        'en_BS': ['en_CB'],
        'en_CW': ['en_CB'],
        'en_DM': ['en_CB'],
        'en_GD': ['en_CB'],
        'en_HT': ['en_CB'],
        'en_JM': ['en_CB'],
        'en_KN': ['en_CB'],
        'en_KY': ['en_CB'],
        'en_LC': ['en_CB'],
        'en_SX': ['en_CB'],
        'en_TC': ['en_CB'],
        'en_TT': ['en_CB'],
        'en_VC': ['en_CB'],
        'en_VG': ['en_CB'],
  
        'es_US': ['es'],
        'es_AR': ['es'],
        'es_BO': ['es'],
        'es_CL': ['es'],
        'es_CO': ['es'],
        'es_CR': ['es'],
        'es_DO': ['es'],
        'es_EC': ['es'],
        'es_GT': ['es'],
        'es_HN': ['es'],
        'es_MX': ['es'],
        'es_NI': ['es'],
        'es_PA': ['es'],
        'es_PE': ['es'],
        'es_PR': ['es'],
        'es_PY': ['es'],
        'es_SV': ['es'],
        'es_UY': ['es'],
        'es_VE': ['es'],
  
        'en_CA': ['en_US'],
        'en_BZ': ['en_US'],
        'en_PR': ['en_US'],
        
        'pt_BR': ['pt'],
        'pt_PT': ['pt', 'en_EU'],
  
        'en_BE': ['en_EU'],
        'en_CH': ['en_EU'],
        'en_CZ': ['en_EU'],
        'en_DE': ['en_EU'],
        'en_DK': ['en_EU'],
        'en_ES': ['en_EU'],
        'en_FI': ['en_EU'],
        'en_FR': ['en_EU'],
        'en_GB': ['en_EU'],
        'en_GR': ['en_EU'],
        'en_HR': ['en_EU'],
        'en_HU': ['en_EU'],
        'en_IE': ['en_EU'],
        'en_IL': ['en_EU'],
        'en_IS': ['en_EU'],
        'en_IT': ['en_EU'],
        'en_MA': ['en_EU'],
        'en_ME': ['en_EU'],
        'en_NL': ['en_EU'],
        'en_NO': ['en_EU'],
        'en_PL': ['en_EU'],
        'en_PT': ['en_EU'],
        'en_QA': ['en_EU'],
        'en_RU': ['en_EU'],
        'en_SE': ['en_EU'],
        
        'fr_BE': ['fr'],
        'fr_CA': ['fr'],
        'fr_CH': ['fr'],
        'fr_FR': ['fr'],
        'fr_MA': ['fr'],
  
        'it_IT': ['it', 'en_EU'], 
        'nl_NL': ['nl', 'en_EU'],
        'nl_BE': ['nl', 'en_EU'],
        'es_ES': ['es', 'en_EU'],
        'de_DE': ['de', 'en_EU'],
        'de_CH': ['de', 'en_EU'],
        'fi_FI': ['fi', 'en_EU'],
        'ru_RU': ['ru', 'en_EU'],
  
        'en_AU': ['en_AP'],
        'en_CN': ['en_AP'],
        'en_HK': ['en_AP'],
        'en_IN': ['en_AP'],
        'en_JP': ['en_AP'],
        'en_KR': ['en_AP'],
        'en_NZ': ['en_AP'],

        'ja_JP': ['ja', 'en_AP'],
        'ko_KR': ['ko', 'en_AP'],
        'zh_CN': ['zh', 'en_AP'],
        'zh_HK': ['zh', 'en_AP']
      }
    };
  }
